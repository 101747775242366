<script lang="ts" setup>
  import { RouteRecord } from 'vue-router'
  import useBem from '~/composables/Bem/Bem'
  import { UseBemProps } from '~/composables/Bem/BemFacetOptions'
  import { resolveComponent } from '#imports'

  // NOTE: Props extension currently not supported
  // https://vuejs.org/guide/typescript/composition-api.html#typing-component-props
  interface Props extends UseBemProps {
    allowAnimation?: boolean
    doAnimation?: boolean
    facets?: Array<string>
    url?: RouteRecord
  }

  const NuxtLink = resolveComponent('NuxtLink') as string

  const props = withDefaults(defineProps<Props>(), {
    allowAnimation: false,
    doAnimation: false,
    facets: () => [],
    url: undefined,
  })
  const { bemFacets } = useBem<Props>('c-logo-as-code', props, {})
</script>

<template>
  <div :class="bemFacets" class="c-logo-as-code">
    <div class="c-logo-as-code__wrap">
      <component
        :is="url ? NuxtLink : 'div'"
        :to="url ? url : null"
        class="u-reset c-logo-as-code__outer c-logo-as-code__outer--left"
      >
        <div class="c-logo-as-code__inner">
          <span class="c-logo-as-code__text"> ciné </span>
        </div>
      </component>
      <component
        :is="url ? NuxtLink : 'div'"
        :to="url ? url : null"
        class="u-reset c-logo-as-code__outer c-logo-as-code__outer--right"
      >
        <div class="c-logo-as-code__inner">
          <span class="c-logo-as-code__text"> cité </span>
        </div>
      </component>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  @use 'sass:map';
  @use 'sass:math';
  @use '@nirazul/scss-utils' as utils;
  @use '/assets/scss/base/mq' as mq;
  @use '/assets/scss/util/color/color' as col;
  @use '/assets/scss/base/font/font' as font;
  @use '/assets/scss/util/anaglyph/anaglyph' as anaglyph;

  @function get-value($values, $nodes...) {
    @return map.get($values, $nodes...);
  }

  @mixin apply-scale($width) {
    $height: math.div(100% * 9, 16);
    $offset-y: 0.125 * $width;
    $offset-shift: 0.0375 * 0.75 * $width;
    $offset-x-between: 0;
    $font-size: 0.319em;

    // prettier-ignore
    $v: (
      left: (
        main: (x: 0deg, y: -15deg, z: 0deg, tX: 0, tY: 0, o: 'center right'),
        before: (x: 0deg, y: 0deg, z: 0deg, tX: $offset-shift * -1, tY: $offset-shift * 1),
        after: (x: 0deg, y: 0deg, z: 0deg, tX: $offset-shift * 1, tY: $offset-shift * -1),
      ),
      right: (
        main: (x: 0deg, y: 15deg, z: 0deg, tX: 0, tY: 0, o: 'center left'),
        before: (x: 0deg, y: 0deg, z: 0deg, tX: $offset-shift * 1, tY: $offset-shift * 1),
        after: (x: 0deg, y: 0deg, z: 0deg, tX: $offset-shift * -1, tY: $offset-shift * -1),
      ),
    );

    .c-logo-as-code__wrap {
      perspective: $width;
    }

    .c-logo-as-code__outer {
      @include anaglyph.with-helper-els-main-styles($offset-shift);
      padding: $offset-shift;

      &::before {
        @include anaglyph.with-helper-els-shift-first(col.$brand-red, $offset-shift, $offset-shift);
      }

      &::after {
        @include anaglyph.with-helper-els-shift-first(col.$brand-cyan, $offset-shift * -1, $offset-shift * -1);
      }
    }

    .c-logo-as-code__outer {
      position: absolute;
      width: $width;

      @each $side in ('left', 'right') {
        &.c-logo-as-code__outer--#{$side} {
          transform-origin: #{get-value($v, $side, 'main', 'o')};
          transform: translate(-50%, -50%)
            translateX($width * if($side == 'left', -0.5, 0.5))
            translateX($offset-x-between * if($side == 'left', -0.5, 0.5))
            translateY($offset-y * if($side == 'left', -0.5, 0.5))
            rotateX(#{get-value($v, $side, 'main', 'x')})
            rotateY(#{get-value($v, $side, 'main', 'y')})
            rotateZ(#{get-value($v, $side, 'main', 'z')})
            translateX(#{get-value($v, $side, 'main', 'tX')})
            translateY(#{get-value($v, $side, 'main', 'tY')});

          @each $elem in ('before', 'after') {
            &::#{$elem} {
              transform: rotateX(#{get-value($v, $side, $elem, 'x')})
                rotateY(#{get-value($v, $side, $elem, 'y')})
                rotateZ(#{get-value($v, $side, $elem, 'z')})
                translateX(#{get-value($v, $side, $elem, 'tX')})
                translateY(#{get-value($v, $side, $elem, 'tY')});
            }
          }
        }
      }

      .c-logo-as-code__text {
        font-size: $font-size;
        letter-spacing: $font-size * 0.19;
        transform: translate(-50%, -50%) //
          translateX($font-size * 0.008) //
          translateY($font-size * 0.008);
      }
    }

    .c-logo-as-code__inner {
      pointer-events: none;
      font-size: $width;
      padding-top: $height;
    }
  }

  .c-logo-as-code {
    @include utils.is-selectable(false);
    pointer-events: none;
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  .c-logo-as-code--in-navigation-game {
    @include apply-scale(120px);
    width: var(--c-navigation-game--logo-width);
    height: var(--s-layout-game--header-height);

    .c-logo-as-code__wrap {
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 0);
      width: var(--c-navigation-game--logo-width);
      height: var(--c-navigation-game--logo-height);
    }
  }

  .c-logo-as-code--in-title-screen {
    @include apply-scale(60vmin);

    height: 100%;

    .c-logo-as-code__wrap {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
    }
  }

  .c-logo-as-code--is-positive {
    .c-logo-as-code__outer {
      color: col.$monochrome-black;
      background-color: col.$monochrome-white;
    }
  }

  .c-logo-as-code--is-negative {
    .c-logo-as-code__outer {
      color: col.$monochrome-white;
      background-color: col.$monochrome-black;
    }
  }

  .c-logo-as-code__wrap {
    overflow: hidden;
    position: absolute;
  }

  .c-logo-as-code__outer {
    &::before,
    &::after {
      @include anaglyph.with-helper-els-helper-styles;
      pointer-events: none;
      mix-blend-mode: multiply;
    }

    pointer-events: auto;
    overflow: visible;
    position: absolute;
    top: 50%;
    left: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .c-logo-as-code__inner {
    width: 100%;
  }

  .c-logo-as-code__text {
    @include font.logo-regular;
    text-transform: uppercase;

    z-index: 2;
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
  }

  .c-logo-as-code__outer.c-logo-as-code__outer--right .text {
    margin-left: -0.5rem;
  }
</style>
